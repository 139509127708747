import React from 'react';
import Api from '../../../js/Api';
import Utilities from '../../../js/Utilities';
import StickerTable from '../../Common/StickerTable/StickerTable';
import Totals from '../../Common/Totals/Totals';
import ReportsHeader from '../../Common/ReportsHeader/ReportsHeader';
import './Reports.css';
import '../../Common/StatsTable/StatsTable.css';
import Loader from '../../Common/Loader/Loader';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight} from '@fortawesome/free-solid-svg-icons';
import PackagingChecklist from './PackagingChecklist';
import OrderTable from './OrderTable';
const BRANCH_NAME = "Got Tea - Safdarjung";
const BRANCH_CODE = "GOT-001";
const Client = new Api();

class PackagingList extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading:true,
            browser : Utilities.getCurrentBrowser(),
            money : 0.0,
            data : null,
            stats : null,
            deliveryOrders: []
        }
    }

    

    markOrderReady = async (orderId) => {
        await Client.markReady(orderId, "Processed");
        // alert(`ORDER READY CALLED FOR ORDER ${orderId}`);
        this.loadOrders();
    }

    loadOrders = async ()=>{
        this.setState({
            loading:true
        },()=>{
            Client.getDeliveryOrders(BRANCH_CODE).then((deliveryOrders)=>{
                this.setState({
                    deliveryOrders,
                    loading:false
                });
                
            }).catch((err)=>{
                console.log('GET GOT TEA DATA ERROR =>', err);
            });
        })
    }


    componentDidMount(){
        let mainThis = this;
        firebase.auth().onAuthStateChanged(user => {
            let loggedIn = !!user;
            if(loggedIn){
                this.loadOrders();
            } else {
                window.location.href = '/login';
            }
        });

        // Set up an interval to refresh data every 15 seconds
        mainThis.interval = setInterval(() => {
            this.loadOrders();
        }, 15000); // 15 seconds = 15000 milliseconds
    }

    componentWillUnmount() {
        // Clear the interval when the component unmounts to avoid memory leaks
        clearInterval(this.interval);
    }
    
    setStartDate = (e) => {
        this.setState({startDate : e.target.value},()=>{
        });
    }

    onUpdateClick = ()=>{
        this.loadOrders();
    }

    setEndDate = (e) => {
        this.setState({endDate : e.target.value},()=>{
        });
    }

    render(){
        const { 
            deliveryOrders,
            loading,
         } = this.state;
        
     
        return <div className='reportContainer'>
            <ReportsHeader/>
            <div className="container mx-auto p-4">
            {deliveryOrders && deliveryOrders.length > 0 ? (
            <OrderTable orders={deliveryOrders} markOrderReady={this.markOrderReady}/>
            ) : (
            <p>No orders available.</p>
            )}
            </div>
            </div>
    } 
}

export default PackagingList;